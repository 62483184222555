// src/components/Analytics.js
import React, { useState, useEffect } from 'react';
import { fetchAnalytics } from '../services/api';
import Card from './ui/Card';

const Analytics = () => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadAnalytics();
  }, []);

  const loadAnalytics = async () => {
    try {
      setLoading(true);
      const response = await fetchAnalytics();
      setAnalyticsData(response.data);
      setError(null);
    } catch (err) {
      setError('Failed to load analytics. Please try again.');
      console.error('Error loading analytics:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading analytics...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <Card className="p-4">
      <h2 className="text-xl font-bold mb-4">Repair Analytics</h2>
      {analyticsData && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="bg-blue-100 p-4 rounded">
            <h3 className="font-semibold">Total Repairs</h3>
            <p className="text-2xl">{analyticsData.totalRepairs}</p>
          </div>
          <div className="bg-green-100 p-4 rounded">
            <h3 className="font-semibold">Completed Repairs</h3>
            <p className="text-2xl">{analyticsData.completedRepairs}</p>
          </div>
          <div className="bg-yellow-100 p-4 rounded">
            <h3 className="font-semibold">Pending Repairs</h3>
            <p className="text-2xl">{analyticsData.pendingRepairs}</p>
          </div>
          <div className="bg-purple-100 p-4 rounded">
            <h3 className="font-semibold">Average Repair Time</h3>
            <p className="text-2xl">{analyticsData.averageRepairTime} hours</p>
          </div>
        </div>
      )}
    </Card>
  );
};

export default Analytics;