// src/components/ui/Button.js
import React from 'react';

const Button = ({ children, variant = 'default', size = 'md', className = '', ...props }) => {
  const baseStyles = 'font-bold rounded focus:outline-none focus:ring-2 focus:ring-offset-2';
  const variants = {
    default: 'bg-blue-500 hover:bg-blue-600 text-white focus:ring-blue-500',
    outline: 'border border-gray-300 hover:bg-gray-50 focus:ring-blue-500',
  };
  const sizes = {
    sm: 'px-2 py-1 text-sm',
    md: 'px-4 py-2',
    lg: 'px-6 py-3 text-lg',
  };

  const classes = `${baseStyles} ${variants[variant]} ${sizes[size]} ${className}`;

  return (
    <button className={classes} {...props}>
      {children}
    </button>
  );
};

export default Button;
