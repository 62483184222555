// src/components/Schedule.js
import React, { useState, useEffect } from 'react';
import { fetchSchedule, addScheduleItem, updateScheduleItem, deleteScheduleItem } from '../services/api';
import Card from './ui/Card';
import Button from './ui/Button';

const Schedule = () => {
  const [scheduleItems, setScheduleItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadSchedule();
  }, []);

  const loadSchedule = async () => {
    try {
      setLoading(true);
      const response = await fetchSchedule();
      setScheduleItems(response.data);
      setError(null);
    } catch (err) {
      setError('Failed to load schedule. Please try again.');
      console.error('Error loading schedule:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddItem = async (newItem) => {
    try {
      const response = await addScheduleItem(newItem);
      setScheduleItems([...scheduleItems, response.data]);
    } catch (err) {
      setError('Failed to add schedule item. Please try again.');
      console.error('Error adding schedule item:', err);
    }
  };

  const handleUpdateItem = async (id, updatedItem) => {
    try {
      await updateScheduleItem(id, updatedItem);
      setScheduleItems(scheduleItems.map(item => item.id === id ? { ...item, ...updatedItem } : item));
    } catch (err) {
      setError('Failed to update schedule item. Please try again.');
      console.error('Error updating schedule item:', err);
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      await deleteScheduleItem(id);
      setScheduleItems(scheduleItems.filter(item => item.id !== id));
    } catch (err) {
      setError('Failed to delete schedule item. Please try again.');
      console.error('Error deleting schedule item:', err);
    }
  };

  if (loading) return <div>Loading schedule...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <Card className="p-4">
      <h2 className="text-xl font-bold mb-4">Repair Schedule</h2>
      <Button onClick={() => handleAddItem({ date: new Date(), vehicleId: '', serviceType: '' })}>
        Add New Schedule Item
      </Button>
      <ul className="mt-4">
        {scheduleItems.map(item => (
          <li key={item.id} className="mb-2 p-2 border rounded">
            <p>Date: {new Date(item.date).toLocaleDateString()}</p>
            <p>Vehicle ID: {item.vehicleId}</p>
            <p>Service Type: {item.serviceType}</p>
            <Button onClick={() => handleUpdateItem(item.id, { ...item })} className="mr-2">Edit</Button>
            <Button onClick={() => handleDeleteItem(item.id)} variant="outline">Delete</Button>
          </li>
        ))}
      </ul>
    </Card>
  );
};

export default Schedule;