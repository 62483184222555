import './App.css';
import React from 'react';
   import Dashboard from './components/Dashboard';

   function App() {
     return (
       <div className="App">
         <Dashboard />
       </div>
     );
   }

   export default App;