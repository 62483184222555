import React, { useState, useEffect, useCallback } from 'react';
import { fetchRepairs, fetchInventory, fetchSchedule, fetchAnalytics } from '../services/api';
import Button from './ui/Button';
import Card from './ui/Card';
import { Car, Package, BarChart2, Calendar, User, FileText } from 'lucide-react';
import NewRepairForm from './NewRepairForm';
import Inventory from './Inventory';
import Schedule from './Schedule';
import Analytics from './Analytics';
import DailyManifest from './DailyManifest';

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('repairs');
  const [repairs, setRepairs] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [analytics, setAnalytics] = useState(null);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setError(null);
    try {
      switch (activeTab) {
        case 'repairs':
          const repairsResponse = await fetchRepairs();
          setRepairs(repairsResponse.data);
          break;
        case 'inventory':
          const inventoryResponse = await fetchInventory();
          setInventory(inventoryResponse.data);
          break;
        case 'schedule':
          const scheduleResponse = await fetchSchedule();
          setSchedule(scheduleResponse.data);
          break;
        case 'analytics':
          const analyticsResponse = await fetchAnalytics();
          setAnalytics(analyticsResponse.data);
          break;
        // No need to fetch data for 'manifest' as it's handled within the DailyManifest component
        default:
          // Do nothing for unknown tabs
      }
    } catch (error) {
      console.error(`Error fetching ${activeTab} data:`, error);
      setError(error.response?.data?.message || `An error occurred while fetching ${activeTab} data`);
    }
  }, [activeTab]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const menuItems = [
    { key: 'repairs', icon: <Car size={16} />, label: 'Repairs' },
    { key: 'inventory', icon: <Package size={16} />, label: 'Inventory' },
    { key: 'schedule', icon: <Calendar size={16} />, label: 'Schedule' },
    { key: 'analytics', icon: <BarChart2 size={16} />, label: 'Analytics' },
    { key: 'manifest', icon: <FileText size={16} />, label: 'Daily Manifest' },
  ];

  const getStatusClass = (status) => {
    switch (status) {
      case 'completed': return 'bg-green-100 text-green-800';
      case 'in_progress': return 'bg-blue-100 text-blue-800';
      default: return 'bg-yellow-100 text-yellow-800';
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'repairs':
        return (
          <>
            <NewRepairForm onRepairAdded={(newRepair) => {
              setRepairs([...repairs, newRepair]);
            }} />
            <Card className="p-4 mt-8">
              <h3 className="text-lg font-semibold mb-4">Recent Repairs</h3>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Vehicle ID</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Scheduled Date</th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {repairs.map((repair) => (
                      <tr key={repair.id}>
                        <td className="px-6 py-4 whitespace-nowrap">{repair.id}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{repair.vehicleId}</td>
                        <td className="px-6 py-4 whitespace-nowrap">{repair.description}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 py-1 rounded-full text-xs font-semibold ${getStatusClass(repair.status)}`}>
                            {repair.status}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">{new Date(repair.scheduledDate).toLocaleDateString()}</td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <Button variant="outline" size="sm" className="mr-2">View</Button>
                          <Button variant="outline" size="sm">Edit</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
          </>
        );
      case 'inventory':
        return <Inventory inventory={inventory} />;
      case 'schedule':
        return <Schedule schedule={schedule} />;
      case 'analytics':
        return <Analytics analytics={analytics} />;
      case 'manifest':
        return <DailyManifest />;
      default:
        return <p>Select a tab to view content.</p>;
    }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-100">
      <aside className="w-full md:w-64 bg-white shadow-md">
        <div className="p-4">
          <h1 className="text-xl font-bold">Vehicle Repair App</h1>
        </div>
        <nav>
          {menuItems.map((item) => (
            <button
              key={item.key}
              onClick={() => setActiveTab(item.key)}
              className={`flex items-center w-full px-4 py-2 text-left
                ${activeTab === item.key ? 'bg-gray-200' : 'hover:bg-gray-100'}`}
            >
              {item.icon}
              <span className="ml-2">{item.label}</span>
            </button>
          ))}
        </nav>
      </aside>
      <main className="flex-1 p-4 md:p-8 overflow-auto">
        <header className="flex justify-between items-center mb-8">
          <h2 className="text-2xl font-semibold">{activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}</h2>
          <Button variant="outline" size="sm" className="flex items-center">
            <User size={16} className="mr-2" />
            Login
          </Button>
        </header>
        {error && (
          <Card className="mb-4 bg-red-100 border-red-400 text-red-700 p-4">
            <p>{error}</p>
          </Card>
        )}
        {renderContent()}
      </main>
    </div>
  );
};

export default Dashboard;