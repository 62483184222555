// src/services/api.js

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://transportation.northernlightsservers.com/api';

const api = axios.create({
  baseURL: API_BASE_URL,
});

export const fetchRepairs = () => api.get('/repairs');
export const addRepair = (repairData) => api.post('/repairs', repairData);
export const updateRepair = (id, repairData) => api.put(`/repairs/${id}`, repairData);
export const deleteRepair = (id) => api.delete(`/repairs/${id}`);

export const fetchInventory = () => api.get('/inventory');
export const addInventoryItem = (itemData) => api.post('/inventory', itemData);
export const updateInventoryItem = (id, itemData) => api.put(`/inventory/${id}`, itemData);
export const deleteInventoryItem = (id) => api.delete(`/inventory/${id}`);

export const fetchSchedule = () => api.get('/schedule');
export const addScheduleItem = (scheduleData) => api.post('/schedule', scheduleData);
export const updateScheduleItem = (id, scheduleData) => api.put(`/schedule/${id}`, scheduleData);
export const deleteScheduleItem = (id) => api.delete(`/schedule/${id}`);

export const fetchAnalytics = () => api.get('/analytics');

export default api;