import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import axios from 'axios';
import Button from './ui/Button';
import Select from './ui/Select';
import Card from './ui/Card';

const DailyManifest = () => {
  const [currentManifest, setCurrentManifest] = useState(null);
  const [recentManifests, setRecentManifests] = useState([]);
  const [selectedDate, setSelectedDate] = useState('today');
  const [file, setFile] = useState(null);

  useEffect(() => {
    fetchCurrentManifest();
    fetchRecentManifests();
  }, []);

  const fetchCurrentManifest = async () => {
    try {
      const response = await axios.get('/api/manifests/current');
      setCurrentManifest(response.data);
    } catch (error) {
      console.error('Error fetching current manifest:', error);
    }
  };

  const fetchRecentManifests = async () => {
    try {
      const response = await axios.get('/api/manifests/list');
      setRecentManifests(response.data);
    } catch (error) {
      console.error('Error fetching recent manifests:', error);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('date', selectedDate);

    try {
      await axios.post('/api/manifests/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      fetchCurrentManifest();
      fetchRecentManifests();
    } catch (error) {
      console.error('Error uploading manifest:', error);
    }
  };

  return (
    <Card className="p-4">
      <div className="flex justify-between mb-4">
        <h2 className="text-xl font-bold">Daily Manifest</h2>
        <div>
          <input type="file" onChange={handleFileChange} accept=".pdf" />
          <Select
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            options={[
              { value: 'today', label: 'Today' },
              { value: 'tomorrow', label: 'Tomorrow' },
            ]}
          />
          <Button onClick={handleUpload}>Upload</Button>
        </div>
      </div>
      
      {currentManifest && (
        <Document file={currentManifest.file_path}>
          <Page pageNumber={1} />
        </Document>
      )}

      <h3 className="mt-4 mb-2 font-semibold">Recent Manifests</h3>
      <ul>
        {recentManifests.map((manifest) => (
          <li key={manifest.id}>
            {manifest.date} - {manifest.file_path}
          </li>
        ))}
      </ul>
    </Card>
  );
};

export default DailyManifest;